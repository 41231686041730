_adderDebug = false;

const adderProfiles = [

    {
        selector:'.fader',
        addClass:'fader-fadeIn'
        
    },

    {
        selector:'.riser',
        addClass:'riser-riseUp'
        
    }
    
];


const adderObserver = new IntersectionObserver(entries => {   

    entries.forEach(entry => {
     
      if(entry.isIntersecting) {

            
            if(_adderDebug) console.log('entry dataset', entry);

            const ele = entry.target;
          
            const profileIndex = parseInt(ele.dataset.adderProfileIndex);
            const profile = adderProfiles[profileIndex];

            if(_adderDebug) console.log('profile found', {profile, dataSet:entry.dataset});

            if(!ele.classList.contains(profile.addClass)) {
                ele.classList.add(profile.addClass);
            }
          
      }

    });

});


const setupObjervers = ()=> {

    
    const elements = [];
    
    adderProfiles.forEach((p,index) => {

        found = Array.from(document.querySelectorAll(p.selector));

        if(_adderDebug) console.log(`found for selector:${p.selector}:`, found.length);
        
        found.forEach(f => {
            
            f.dataset.adderProfileIndex = index;
            if(_adderDebug) console.log('dataset', f.dataset);
            elements.push(f);
            
        });

       
        
    });
                          
    //console.log('adder targets found', elements.length);

    elements.forEach(ele => {
        adderObserver.observe(ele);
    });    
    
}


const setupIntersectionAnim = ()=> {

    console.log('setting up intersection anim');
    setupObjervers();
    
}

jQuery(document).ready(()=> {
    setupIntersectionAnim();
});

