(function () {
	'use strict';
	
	const bodyEl = document.querySelector('body');

	let breakpoint = {};
	breakpoint.refreshValue = function () {
		this.value = window.getComputedStyle(bodyEl, ':before').getPropertyValue('content').replace(/\"/g, '');
		// console.log(this.value);
	};

	document.addEventListener("DOMContentLoaded", function () {
		// nav toggle
		const htmlEl = document.querySelector('html');

		if (hasClass(htmlEl , 'touch') || breakpoint.value == 'phone') {
			const menuToggle = document.querySelector('.menu-toggle');

			menuToggle.addEventListener('click', function (e) {
				e.preventDefault();

				let target = this.getAttribute('data-menu');
				toggleClass(document.querySelector('.' + target), 'open');
				toggleClass(bodyEl , 'mobile-nav-open');
			})
		}
	});

	// http://youmightnotneedjquery.com/
	function hasClass(el, className) {
		if (el.classList) {
			return el.classList.contains(className);
		} else {
			return new RegExp('(^| )' + className + '( |$)', 'gi').test(el.className);
		}
	}

	// http://youmightnotneedjquery.com/
	function toggleClass(el, className) {
		if (el.classList) {
			el.classList.toggle(className);
		} else {
			let classes = el.className.split(' ');
			let existingIndex = -1;

			for (let i = classes.length; i--;) {
				if (classes[i] === className) {
					existingIndex = i;
				}
			}

			if (existingIndex >= 0) {
				classes.splice(existingIndex, 1);
			} else { 
				classes.push(className);
			}

			el.className = classes.join(' ');
		}
	}
}());