/* global map_options: false, locs_data: false, google: false, siteurl: false */

document.addEventListener('DOMContentLoaded', function () {
	const mapCanvases = document.querySelectorAll('.cw-map-canvas');

	if (mapCanvases.length) {
		let lang = '';
		if (document.querySelector('html').lang) {
			lang = document.querySelector('html').lang;
		} else{
			lang = 'en';
		}

		var js_file = document.createElement('script');
		js_file.type = 'text/javascript';
		js_file.src = 'https://maps.googleapis.com/maps/api/js?v=3.0&callback=initMap&signed_in=true&key=' + map_options.maps_api_key + '&language=' + lang;
		document.getElementsByTagName('head')[0].appendChild(js_file);
	}

});

window.initMap = function() {
	const mapCanvases = document.querySelectorAll('.cw-map-canvas');

	for (let m = 0; m < mapCanvases.length; m++) {
		const mapCan = mapCanvases[m];

		const postid = mapCan.getAttribute('data-postid');

		const uluru = { lat: parseFloat(locs_data[postid].lat), lng: parseFloat(locs_data[postid].lng) };
		
		const map_style = [
			{
				"elementType": "geometry",
				"stylers": [
					{
						"color": "#212121"
					}
				]
			},
			{
				"elementType": "labels.icon",
				"stylers": [
					{
						"visibility": "off"
					}
				]
			},
			{
				"elementType": "labels.text.fill",
				"stylers": [
					{
						"color": "#757575"
					}
				]
			},
			{
				"elementType": "labels.text.stroke",
				"stylers": [
					{
						"color": "#212121"
					}
				]
			},
			{
				"featureType": "administrative",
				"elementType": "geometry",
				"stylers": [
					{
						"color": "#757575"
					}
				]
			},
			{
				"featureType": "administrative.country",
				"elementType": "labels.text.fill",
				"stylers": [
					{
						"color": "#c2c2c2"
					}
				]
			},
			{
				"featureType": "administrative.locality",
				"elementType": "labels.text.fill",
				"stylers": [
					{
						"color": "#ffffff"
					}
				]
			},
			{
				"featureType": "poi",
				"elementType": "labels.text.fill",
				"stylers": [
					{
						"color": "#757575"
					}
				]
			},
			{
				"featureType": "poi.park",
				"elementType": "geometry",
				"stylers": [
					{
						"color": "#181818"
					}
				]
			},
			{
				"featureType": "poi.park",
				"elementType": "labels.text.fill",
				"stylers": [
					{
						"color": "#a8a8a8"
					}
				]
			},
			{
				"featureType": "poi.park",
				"elementType": "labels.text.stroke",
				"stylers": [
					{
						"color": "#1b1b1b"
					}
				]
			},
			{
				"featureType": "road",
				"elementType": "geometry.fill",
				"stylers": [
					{
						"color": "#8c8c8c"
					}
				]
			},
			{
				"featureType": "road",
				"elementType": "labels.text.fill",
				"stylers": [
					{
						"color": "#ffffff"
					}
				]
			},
			{
				"featureType": "road.arterial",
				"elementType": "geometry",
				"stylers": [
					{
						"color": "#545454"
					}
				]
			},
			{
				"featureType": "road.highway",
				"elementType": "geometry",
				"stylers": [
					{
						"color": "#949494"
					}
				]
			},
			{
				"featureType": "road.highway.controlled_access",
				"elementType": "geometry",
				"stylers": [
					{
						"color": "#9e9e9e"
					}
				]
			},
			{
				"featureType": "road.local",
				"elementType": "labels.text.fill",
				"stylers": [
					{
						"color": "#8f8f8f"
					}
				]
			},
			{
				"featureType": "transit",
				"elementType": "labels.text.fill",
				"stylers": [
					{
						"color": "#757575"
					}
				]
			},
			{
				"featureType": "water",
				"elementType": "geometry",
				"stylers": [
					{
						"color": "#000000"
					}
				]
			},
			{
				"featureType": "water",
				"elementType": "labels.text.fill",
				"stylers": [
					{
						"color": "#949494"
					}
				]
			}
		];

		const icon = {
			url: siteurl + '/wp-content/themes/operation-shield/dist/mapicon-8.png',
			size: new google.maps.Size(30, 32),
			scaledSize: new google.maps.Size(30, 32)		
		};

		let map = new google.maps.Map(mapCan, {
			center: uluru,
			mapTypeId: 'roadmap',
			zoom: parseInt(map_options.zoom),
			scrollwheel: map_options.scroll_zoom,
			disableDefaultUI: true,
			fullscreenControl: false,
			styles: map_style
		});

		new google.maps.Marker({ position: uluru, map: map, icon: icon });
	}
}
