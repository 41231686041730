const accordMothers = document.querySelectorAll('.cw-accordion-mother');
const closeOthers = true;

if (accordMothers.length) {
	for (let a = 0; a < accordMothers.length; a++) {
		const accMother = accordMothers[a];

		const header = accMother.querySelector('.cw-accordion-header');
		const content = accMother.querySelector('.cw-accordion-content');
		
		header.addEventListener('click', function(){
			if(closeOthers) {
				// loop back through all the mothers again, closing all that are open
				for (let b = 0; b < accordMothers.length; b++) {
					const am = accordMothers[b];
	
					// make an exception for the click mother, we'll handle her in just a bit
					if(b != a){
						removeClass(am, 'active');
						const amC = am.querySelector('.cw-accordion-content');
						amC.style.height = '0px';
					}
				}
			}

			toggleClass(accMother, 'active');

			if (hasClass(accMother, 'active')) {
				const inner = content.querySelector('.inner');
				const h = inner.offsetHeight;

				content.style.height = h + 'px';
			} else {
				content.style.height = '0px';
			}
		})
	}
}

function toggleClass(el, className) {
	if (el.classList) {
		el.classList.toggle(className);
	} else {
		let classes = el.className.split(' ');
		let existingIndex = -1;

		for (let i = classes.length; i--;) {
			if (classes[i] === className) {
				existingIndex = i;
			}
		}

		if (existingIndex >= 0) {
			classes.splice(existingIndex, 1);
		} else {
			classes.push(className);
		}

		el.className = classes.join(' ');
	}
}

function hasClass(el, className) {
	if (el.classList) {
		return el.classList.contains(className);
	} else {
		return new RegExp('(^| )' + className + '( |$)', 'gi').test(el.className);
	}
}

function removeClass(el, className) {
	if (el.classList)
		el.classList.remove(className);
	else
		el.className = el.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
}