/* global slideshow_options: false, jQuery: false */

jQuery(function () {
	const $heroSlider = jQuery('.cw-slider');

	let parsedOpts = {
		fade: 0,
		vertical: 0,
		arrows: 0,
		dots: 0,
		speed: 0,
		pause: 0,
	};

	parsedOpts.fade = slideshow_options.fade == 'true' ? true : false;
	parsedOpts.vertical = slideshow_options.vertical == 'true' ? true : false;
	parsedOpts.arrows = slideshow_options.arrows == 'true' ? true : false;
	parsedOpts.dots = slideshow_options.dots == 'true' ? true : false;
	parsedOpts.speed = parseInt(slideshow_options.speed);
	parsedOpts.pause = parseInt(slideshow_options.pause);

	if ($heroSlider) {
		var slides_count = $heroSlider.find('.slide').length;
		if (slides_count > 1) {
			const slkOpts = {
				fade: parsedOpts.fade,
				vertical: parsedOpts.vertical,
				arrows: parsedOpts.arrows,
				dots: parsedOpts.dots,
				speed: parsedOpts.speed,
				autoplaySpeed: parsedOpts.pause,
				autoplay: true,
				pauseOnHover: false,
			};

			$heroSlider.slick(slkOpts);
		} else {
			setTimeout(function () {
				$heroSlider.find('.slide').addClass('slick-active');
			}, 500);
		}
	}
});