import './accordion';
// import 'SRC/js/alerts.js';
// import 'SRC/js/classes.js';
// import 'SRC/js/cw-gf-file-override.js';
// import 'SRC/js/cw-blocks.js';
// import 'SRC/js/faqs.js';
// import 'SRC/js/footer.js';
// import 'SRC/js/galleries.js';
// import 'SRC/js/lazyload.js';
// import 'SRC/js/locations.js';
import './maps';
import './navigation';
// import 'SRC/js/promos.js';
import './search';
// import 'SRC/js/services.js';
// import 'SRC/js/sidebar.js';
import './slideshow';
// import 'SRC/js/staff.js';
// import 'SRC/js/testimonials.js';
import './intersection-adder';